<template>
  <div>
    <b-alert variant="info" class="border border-warning" show>
      This is the <b>Roster Creation</b> interface. Please note that it is still in its <b>Beta</b> phase; 
      so, you may experience unexpected responces/results. If you do, please notify <a href="mailto:webmaster@nzonesports.com">webmaster@nzonesports.com</a> 
      and we will address the reported issue as soon as we can.
    </b-alert>

    <hr />

    <h4>{{ event.title }} <small>(ages {{ event.ageMin }} - {{ event.ageMax }})</small></h4>
    <h6>
      <small>Total Participants: {{ eventRegistrations.length }} |  
      Over All Age Range: {{ overAllAgeRange.min }} - {{ overAllAgeRange.max }}</small>
    </h6>

    <hr />

    <!-- Auto Group -->
    <div class="mb-3" :hidden="!autoAgeGroups">
      <b-btn variant="outline-secondary" @click="autoAgeGroups = !autoAgeGroups" class="mb-2 text-dark" block>Auto Mode</b-btn>

      <b-row>
        <b-col cols="12" lg="3" v-for="(ages, index) in ageGroupSettings" :key="index">
          <b-card :title="'Age Group: ' + ages.ageMin + ' - ' + ages.ageMax" :sub-title="ages.participantOptions.length + ' participants'">
            <p><span 
              class="font-weight-bold"
            >{{ ageGroupTotalParticipants( ages.participantOptions.length, ages.participantsPerGroup ) }} {{ groupTerm }}s</span><br />
            <small>with at least {{ ages.participantsPerGroup }} participants per {{ groupTerm }}</small></p>
            <b-form class="mb-4">

              <b-form-group
                label="Min Age"
                :description="'Minimum age in ' + groupTerm + 's'"
                :label-for="'age-group-' + index + '-min'"
              >
                <b-input
                  v-model.number="ages.ageMin"
                  type="number"
                  size="sm"
                  min="1"
                  :id="'age-group-' + index + '-min'"
                  class="mb-2"
                  placeholder="Min Age"
                  @change="updateTouchingAgeGroup(index)"
                ></b-input>
              </b-form-group>

              <b-form-group
                label="Max Age"
                :description="'Maximum age in ' + groupTerm + 's'"
                :label-for="'age-group-' + index + '-max'"
              >
                <b-input
                  v-model.number="ages.ageMax"
                  type="number"
                  size="sm"
                  min="1"
                  :id="'age-group-' + index + '-max'"
                  class="mb-2"
                  placeholder="Max Age"
                  @change="updateTouchingAgeGroup(index)"
                ></b-input>
              </b-form-group>
              
              <b-form-group
                label="Min Participants "
                :description="'Minimum number of participants per ' + groupTerm"
                :label-for="'age-group-' + index + '-max'"
              >
                <b-input
                  v-model.number="ages.participantsPerGroup"
                  type="number"
                  size="sm"
                  min="1"
                  :id="'age-group-' + index + '-ppg'"
                  class="mb-2"
                  placeholder="Min Players"
                ></b-input>
              </b-form-group>

              <b-button size="sm" variant="primary" @click="splitAgeGroup(index)" block>Split Age Group</b-button>
              <b-button v-if="index" size="sm" variant="warning" @click="removeAgeGroup(index)" class="d-none d-lg-block" block><i class="fa fa-arrow-left"></i> Merge Left</b-button>
              <b-button v-if="index" size="sm" variant="warning" @click="removeAgeGroup(index)" class="d-lg-none" block><i class="fa fa-arrow-up"></i> Merge Up</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>

      <!-- b-form inline class="mb-4">
        <label class="sr-only" for="new-age-group-min">Age Min</label>
        <b-input
          v-model.number="ageGroupSettingsNew.ageMin"
          type="number"
          size="sm"
          min="0"
          id="new-age-group-min"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="Age Min"
        ></b-input>
        
        <label class="sr-only" for="new-age-group-max">Age Max</label>
        <b-input
          v-model.number="ageGroupSettingsNew.ageMax"
          type="number"
          size="sm"
          min="0"
          id="new-age-group-max"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="Age Max"
        ></b-input>
        
        <label class="sr-only" for="new-age-group-ppg">PPG</label>
        <b-input
          v-model.number="ageGroupSettingsNew.participantsPerGroup"
          type="number"
          size="sm"
          min="0"
          id="new-age-group-ppg"
          class="mb-2 mr-sm-2 mb-sm-0"
          placeholder="PPG"
        ></b-input>

        <b-button size="sm" variant="primary">Create New Age Group</b-button>
      </b-form -->

      <b-btn variant="success" @click="createGroups" block><i class="fa fa-check"></i> Create {{ groupTerm }}s</b-btn>
    </div>

    <!-- Create Group -->
    <div :hidden="autoAgeGroups">
      <b-btn variant="outline-secondary" @click="!groups.length ? autoAgeGroups = !autoAgeGroups : null" class="mb-2 text-dark" block>Manual Mode</b-btn>
      <p class="border border-info p-1">Use this top section to create your {{ groupTerm }}s.</p>
      
      <b-container>
        <b-row class="border bg-light" align-v="center">
          <b-col class="my-2" cols="12" lg="3">
            <span class="float-left mr-2">Ages</span>
            <b-btn-group size="sm">
              <b-btn class="group-age" disabled>{{ newGroup.ageMin || '-' }}</b-btn>
              <b-btn><i class="fa fa-arrow-down" @click="setGroupAgeMin('d')"></i></b-btn>
              <b-btn><i class="fa fa-arrow-up" @click="setGroupAgeMin"></i></b-btn>
            </b-btn-group> - 
            <b-btn-group size="sm">
              <b-btn class="group-age" disabled>{{ newGroup.ageMax || '-' }}</b-btn>
              <b-btn><i class="fa fa-arrow-down" @click="setGroupAgeMax('d')"></i></b-btn>
              <b-btn><i class="fa fa-arrow-up" @click="setGroupAgeMax"></i></b-btn>
            </b-btn-group>
          </b-col>
          <b-col class="my-2" cols="12" lg="3">
            <span class="float-left mr-2">Gender</span>
            <b-form-radio-group
            id="radio-group-1"
            v-model="newGroup.gender"
            :options="[
              { value: null, text: 'All' },
              'F',
              'M'
            ]"
            name="radio-options"
            ></b-form-radio-group>
          </b-col>
          <b-col class="my-2" cols="12" lg="3">
            <b-form-input size="sm" v-model="newGroup.name" @keydown.enter="newGroupCreate" placeholder="Group/Team Name"></b-form-input>
          </b-col>
          <b-col class="my-2" cols="12" lg="3">
            <b-button size="sm" variant="outline-success" @click="newGroupCreate" block title="Create New Group/Team">Create</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <hr />

    <b-row>
      <b-col cols="12" lg="3" class="mb-4">

        <!-- PARTICIPANT POOL -->
        <b-list-group>
          <b-list-group-item variant="primary">
            <b-btn-group size="sm" class="ml-1">
              <b-btn
                @click="sortByProp(participantOptions, 'age')"
                v-b-tooltip.hover
                title="Sort By Age"
              ><i class="fa fa-sort"></i></b-btn>
              <b-btn @click="participantOptionsShowF = !participantOptionsShowF" :pressed="participantOptionsShowF">F</b-btn>
              <b-btn @click="participantOptionsShowM = !participantOptionsShowM" :pressed="participantOptionsShowM">M</b-btn>
              <b-btn disabled>{{ participantOptions.length }}</b-btn>
              <b-btn v-b-tooltip.hover title="Auto Sort" @click="autoSort"><i class="fa fa-arrow-right"></i></b-btn>
            </b-btn-group>
          </b-list-group-item>

          <!-- draggable class="p-2 border" :list="participantOptions" group="participants" @change="log" -->
            <b-list-group-item
              v-for="(participant, index) in participantOptions"
              class="p-2"
              :hidden="(
                (participant.gender === 'F' && !participantOptionsShowF) || 
                (participant.gender === 'M' && !participantOptionsShowM)
              )"
              :key="index"
            >
              <select class="form-control w-25 float-right autowidth" @change="moveObjectFromPool($event, index)">
                  <option value="">...</option>
                  <option
                    v-for="group in groups"
                    :value="group.value"
                    class="form-group autowidth"
                    :key="group.value"
                  >
                    <span v-if="group.ageMin">{{ group.ageMin }} - {{ group.ageMax }}: </span>{{ group.name }} ({{ participantGroups[group.value] ? participantGroups[group.value].length : 0 }} participants)
                  </option>
                </select>
                <router-link :to="{ name: 'participantDetails', params: { pid: participant.id } }" class="text-dark" target="_blank">{{ participant.fullName }}</router-link><br />
                <small>{{ participant.age }} | {{ participant.gender }}<span v-if="participant.skillLevel"> | <span :class="'px-1 sl-' + participant.skillLevel.toLowerCase()">{{ participant.skillLevel }}</span></span>
                <div v-if="participant.request" class="border border-dark p-1 mt-1">{{ participant.request }}</div></small>
            </b-list-group-item>
          <!-- /draggable -->
        </b-list-group>
      </b-col>

      <b-col cols="12" lg="9">
        
        <b-btn
          v-if="groups.length"
          class="pt-0"
          variant="link"
          @click="showGroupLists = !showGroupLists"
        >
          <span v-if="!showGroupLists">Show</span><span v-else>Hide</span> Lists
        </b-btn>

        <b-btn
          v-if="groups.length && showGroupLists"
          class="pt-0"
          variant="link"
          @click="sortParticipantGroupsByProp('age')"
        >Sort By Age</b-btn>

        <b-btn
          v-if="groups.length && showGroupLists"
          class="pt-0"
          variant="link"
          @click="sortParticipantGroupsByProp('skillLevel')"
        >Group By Skill Level</b-btn>

        <b-btn
          v-if="groups.length && showGroupLists"
          class="pt-0"
          variant="link"
          @click="showRequests = !showRequests"
        >{{ !showRequests ? 'Show' : 'Hide' }} Requests</b-btn>

        <!-- PARTICIPANT GROUPS -->
        <b-card v-for="(ageGroup, ageGroupsIndex) in ageGroups" :key="'age-group-' + ageGroupsIndex">
          <h3 v-if="ageGroup[0].ageMin">{{ ageGroupsIndex }} <small>({{ ageGroup.length }} {{ groupTerm }}s)</small></h3>
          <b-row class="mb-3">
            <b-col
              v-for="(currentGroup, ageGroupIndex) in ageGroup"
              cols="12"
              lg="3"
              class="roster-create-pg mb-1"
              :key="'col-' + ageGroupsIndex + '-' + ageGroupIndex"
            >
              <b-list-group flush>

                <!-- GROUP HEADER -->
                <b-list-group-item variant="secondary" class="p-1">
                  <div class="mb-1">
                    <!-- b-btn
                      v-if="showGroupLists"
                      size="sm"
                      class="mr-1"
                      @click="sortByProp(participantGroups[currentGroup.value], 'age')"
                      v-b-tooltip.hover
                      title="Sort By Age"
                    >
                      <i class="fa fa-sort"></i>
                    </b-btn -->
                    <small><b>{{ currentGroup.name }}</b></small>
                    <b-badge v-if="participantGroups[currentGroup.value]" class="float-right p-1">
                      {{ participantGroups[currentGroup.value] ? participantGroups[currentGroup.value].length : 0 }} | 
                      {{ participantGroups[currentGroup.value].filter(p => p.gender === 'M').length }} M | 
                      {{ participantGroups[currentGroup.value].filter(p => p.gender === 'F').length }} F
                      <!-- | <a href="#"
                        @click="removeGroup(ageGroupIndex)"
                        class="text-danger"
                        v-b-tooltip.hover
                        title="Remove this group"
                      ><i class="fa fa-remove"></i></a> -->
                    </b-badge>
                  </div>
                  <b-form-select
                    size="sm" :options="userList"
                    v-model="groups[currentGroup.value].coach"
                    value-field="@id"
                    text-field="fullName"
                  >
                  <template v-slot:first>
                    <option :value="null">-- No Coach --</option>
                  </template>
                  </b-form-select>
                </b-list-group-item>

                <!-- draggable class="p-2 border" :list="participantGroups[currentGroup.value]" group="participants" @change="log" -->
                  <template v-if="showGroupLists">
                    <b-list-group-item
                      v-for="(participant, index2) in participantGroups[currentGroup.value]"
                      class="p-2 animated fadeIn"
                      :key="index2"
                    >
                      <b-input-group class="float-right w-25">
                        <select @change="moveObject(currentGroup.value, $event, index2)" class="form-control autowidth">
                          <option value="">...</option>
                          <option value="-">To Pool</option>

                            <template v-for="(toGroup, index3) in groups">
                              <option
                                :class="groupOptionClass[toGroup.ageMin]"
                                :value="toGroup.value"
                                :key="index3"
                                :disabled="toGroup.value === currentGroup.value"
                              >
                                <span v-if="toGroup.ageMin">{{ toGroup.ageMin }} - {{ toGroup.ageMax }}: </span>
                                {{ toGroup.name }} ({{ toGroup.coach ? userList.filter(opt => opt['@id'] === toGroup.coach)[0].fullName : 'No Coach' }} / {{ participantGroups[toGroup.value].length }} participants)
                              </option>
                            </template>

                          </select>
                          <!-- b-input-group-append><b-btn variant="warning" @click="returnObjectToPool(currentGroup.value, index2)"><i class="fa fa-times"></i></b-btn></b-input-group-append -->
                        </b-input-group>
                      <router-link :to="{ name: 'participantDetails', params: { pid: participant.id } }" target="_blank" class="text-dark">{{ participant.fullName }}</router-link><br />
                      <small>{{ participant.age }} | {{ participant.gender }}<span v-if="participant.skillLevel"> | <span :class="'px-1 sl-' + participant.skillLevel.toLowerCase()">{{ participant.skillLevel }}</span></span>
                      <div v-if="showRequests && participant.request" class="border border-dark p-1 mt-1">{{ participant.request }}</div></small>
                    </b-list-group-item>
                  </template>
                <!-- /draggable -->

              </b-list-group>
            </b-col>
          </b-row>
        </b-card>
        <div>
          <b-btn variant="danger" @click="resetEventGroups" :disabled="!Object.keys(participantGroups).length">Reset</b-btn>
          <b-btn variant="success" @click="saveEventGroups" :disabled="!Object.keys(participantGroups).length" class="float-right">Save</b-btn>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { constants } from '@/shared/constants'
// import introJs from 'intro.js'
// import 'intro.js/introjs.css'
// import VueIntro from 'vue-introjs'
// import Draggable from 'vuedraggable'

const NEW_GROUP = {
  id: null,
  name: null,
  ageMin: null,
  ageMax: null,
  gender: null,
  coach: null
}

export default {
  name: 'RosterCreate',
  data () {
    return {
      defGpNm: [null,'A','B','C','D','E','F','G','H','I','J','K',
      'L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
      defDDClass: ['bg-blue', 'bg-green', 'bg-yellow', 'bg-orange', 'bg-red', 'bg-cyan',
        'bg-blue', 'bg-green', 'bg-yellow', 'bg-orange', 'bg-red', 'bg-cyan',
        'bg-blue', 'bg-green', 'bg-yellow', 'bg-orange', 'bg-red', 'bg-cyan',
        'bg-blue', 'bg-green', 'bg-yellow', 'bg-orange', 'bg-red', 'bg-cyan'],
      showRequests: false,
      event: {},
      overAllAgeRange: {},

      // age group settings
      autoAgeGroups: true,
      ageGroupSettingsNew: {
        ageMin: 0,
        ageMax: 0,
        participantsPerGroup: 0,
        participantOptions: []
      },
      ageGroupSettings: [{
        ageMin: 0,
        ageMax: 0,
        participantsPerGroup: 7,
        participantOptions: []
      }],
      ageGroups: {},

      // participant groups
      sortDir: 'asc',
      participantOptionsShowM: true,
      participantOptionsShowF: true,
      participantOptions: [],
      newGroup: {},
      groups: [],
      showGroupLists: false,
      participantGroups: {},
      participantGroupDetails: {}
    }
  },
  computed: {
    ...mapGetters([
      'userList',
      'eventGroups',
      'eventQuestions',
      'eventRegistrations'
    ]),
    groupOptionClass () {
      if (this.ageGroupSettings.length < 2) {
        return ['']
      }
      // @TODO rethink this LOL
      return this.defDDClass
    },
    groupTerm () {
      return this.event.type === 1 ? 'group' : 'team'
    }
  },
  methods: {
    ...mapActions([
      'getUsers',
      'getEvent',
      'getEventQuestions',
      'getEventRegistrations',
      'getEventGroups',
      'postEventGroup',
      'putEventGroup',
      'deleteEventGroup'
    ]),

    participantsAgeRange (participants, constraint) {
      let min = participants[0].age
      let max = participants[0].age
      for (let i = 1, len = participants.length; i < len; i++) {
        let v = participants[i].age
        min = (v < min && (!constraint || v >= this.event.ageMin)) ? v : min
        max = (v > max && (!constraint || v <= this.event.ageMax)) ? v : max
      }
      return {
        min: min,
        max: max
      }
    },
    // age group settings
    iniAgeGroupSettings () {
      let overallAgeRange = this.participantsAgeRange(this.participantOptions, true)
      this.ageGroupSettings[0].ageMin = overallAgeRange.min
      this.ageGroupSettings[0].ageMax = overallAgeRange.max
      this.ageGroupSettings[0].participantOptions = [ ...this.participantOptions ]
    },
    updateAgeGroupSettings () {
      const participantOptions = [ ...this.participantOptions ]
      for (const gk in this.ageGroupSettings) {
        this.ageGroupSettings[gk].participantOptions = []
        for (const pk in participantOptions) {
          if (
            participantOptions[pk].age >= this.ageGroupSettings[gk].ageMin &&
            participantOptions[pk].age <= this.ageGroupSettings[gk].ageMax
          ) {
            this.ageGroupSettings[gk].participantOptions.push(participantOptions[pk])
          }
        }
      }
    },
    splitAgeGroup (index) {
      const oldGroup = { ...this.ageGroupSettings[index] }
      const newGroup = { ...oldGroup }
      newGroup.ageMin = Math.ceil(((oldGroup.ageMax - oldGroup.ageMin) / 2) + oldGroup.ageMin)
      this.ageGroupSettings[index].ageMax = (newGroup.ageMin - 1)
      this.ageGroupSettings.push(newGroup)
      this.sortByProp(this.ageGroupSettings, 'ageMin', 'asc')
      this.updateAgeGroupSettings()
      this.showSuccess({ title: 'Split Complete' })
    },
    removeAgeGroup (index) {
      // console.log('removeAgeGroup', index)
      let participants = this.ageGroupSettings[(index - 1)].participantOptions
      .concat(this.ageGroupSettings[index].participantOptions)
      this.ageGroupSettings[(index - 1)].participants = participants
      this.ageGroupSettings[(index-1)].ageMax = this.ageGroupSettings[index].ageMax
      this.$delete( this.ageGroupSettings, index )
      this.updateAgeGroupSettings()
      this.showSuccess({ title: 'Merge Complete' })
    },
    updateTouchingAgeGroup (index) {
      const prevIndex = (index - 1)
      const nextIndex = (index + 1)
      if (this.ageGroupSettings[prevIndex]) {
        this.ageGroupSettings[prevIndex].ageMax = (this.ageGroupSettings[index].ageMin - 1)
      }
      if (this.ageGroupSettings[nextIndex]) {
        this.ageGroupSettings[nextIndex].ageMin = (this.ageGroupSettings[index].ageMax + 1)
      }
      this.updateAgeGroupSettings()
    },
    createGroups () {
      let n = 1
      for (const k in this.ageGroupSettings) {
        let totalGroups = this.ageGroupTotalParticipants( this.ageGroupSettings[k].participantOptions.length,
          this.ageGroupSettings[k].participantsPerGroup ),
        gpNm = this.event.type === 1 ? this.defGpNm : [ ...this.defGpNm.keys() ]
        for (let i = 0; i < totalGroups; i++) {
          this.newGroup = {
            name: gpNm[n].toString(),
            ageMin: this.ageGroupSettings[k].ageMin,
            ageMax: this.ageGroupSettings[k].ageMax,
            gender: null
          }
          this.newGroupCreate()
          n++
        }
      }
      this.autoAgeGroups = false
    },
    ageGroupTotalParticipants (participants, perGroup) {
      return Math.floor((participants / perGroup))
    },

    // age groups
    newGroupCreate () {
      if (!this.newGroup.name) {
        this.showError({ message: 'Please enter a name for the group.' })
        return
      }
      const found = this.groups.find(group => group.name === this.newGroup.name)
      if (found) {
        this.showError({ message: 'An entry with that name already exists.' })
        return
      }
      const value = this.groups.length
      const newGroup = { ...this.newGroup }
      newGroup.value = value
      newGroup.name = newGroup.name
      newGroup.coach = null
      this.groups.push(newGroup)

      const ageGroupsKey = newGroup.ageMin + ' - ' + newGroup.ageMax
      if (!this.ageGroups[ageGroupsKey]) {
        this.$set(this.ageGroups, ageGroupsKey, [])
      }
      this.ageGroups[ageGroupsKey].push(newGroup)
      
      this.$set(this.participantGroups, value, [])
      if (!this.autoAgeGroups) { this.showSuccess({ message: 'New Group Created.', timeout: 700 }) }
    },
    removeGroup (index) {
      // console.log('removeGroup', index)
      const p = { ...this.participantGroups[index] }
      for (const k in p) {
        this.participantOptions.push(p[k])
      }
      delete this.participantGroups[index]
      this.groups.splice(index, 1)
    },
    setGroupAgeMin (dir) {
      if (dir === 'd') {
        if (!this.newGroup.ageMin) { return }
        this.newGroup.ageMin--
        if (this.newGroup.ageMin === 0) { this.newGroup.ageMin = null }
      } else {
        this.newGroup.ageMin++
        if (this.newGroup.ageMax < this.newGroup.ageMin) { this.newGroup.ageMax = this.newGroup.ageMin }
      }
    },
    setGroupAgeMax (dir) {
      if (dir === 'd') {
        if (!this.newGroup.ageMax) { return }
        this.newGroup.ageMax--
        if (!this.newGroup.ageMax) { 
          this.newGroup.ageMin = null
          this.newGroup.ageMax = null
        }
        if (this.newGroup.ageMin > this.newGroup.ageMax) { this.newGroup.ageMin = this.newGroup.ageMax }
      } else {
        this.newGroup.ageMax++
        if (!this.newGroup.ageMin) { this.newGroup.ageMin = 1 }
      }
    },
    setGroupGender (val) {
      this.newGroup.gender = val
    },
    autoSort () {
      let cg = 0
      let participantOptions = []
      // this.sortByProp(this.participantOptions, 'age', 'desc')
      const sl = ['Advanced','Beginner','Elite','Intermediate',null]

      for (const ag in this.ageGroupSettings) {
        // console.log('sort ' + ag)
        this.sortByProp(this.ageGroupSettings[ag].participantOptions, 'age', 'desc')
        for (let i = 0; i < sl.length; i++) {
          // ladies first
          participantOptions = [ ...this.ageGroupSettings[ag].participantOptions ]
          // console.log('participantOptions F ' + sl[i], participantOptions)
          for (let cp = participantOptions.length - 1; cp >= 0; cp--) {
            if (participantOptions[cp].skillLevel === sl[i] && participantOptions[cp].gender === 'F') {
              cg = this.autoSortToGroup(participantOptions[cp], cg)
            }
          }
        // }

        // for (let i = 0; i < sl.length; i++) {
          // then the dudes
          participantOptions = [ ...this.ageGroupSettings[ag].participantOptions ]
          // console.log('participantOptions M ' + sl[i], participantOptions)
          for (let cp = participantOptions.length - 1; cp >= 0; cp--) {
            if (participantOptions[cp].skillLevel === sl[i] && participantOptions[cp].gender === 'M') {
              cg = this.autoSortToGroup(participantOptions[cp], cg)
            }
          }
        }
      }
    },
    autoSortToGroup (participant, cg, loop) {
      // if (cg >= this.groups.length) { cg = 0 }
      
      for (let i = cg; i < this.groups.length; i++) {
        if (
          (!this.groups[i].ageMin || (parseInt(participant.age) >= parseInt(this.groups[i].ageMin))) &&
          (!this.groups[i].ageMax || (parseInt(participant.age) <= parseInt(this.groups[i].ageMax))) &&
          (!this.groups[i].gender || (participant.gender === this.groups[i].gender))
        ) {
          // console.log('cg', i, participant.skillLevel, participant.gender, participant.age)
          this.participantGroups[i].push(participant)
          this.updateParticipantGroupDetails(i, participant)
          let index = this.participantOptions.findIndex(x => x.id === participant.id)
          this.participantOptions.splice(index, 1)
          return (i + 1)
        }
      }
      if (cg && loop !== true) {
        cg = this.autoSortToGroup (participant, 0, true)
      }
      return (cg >= this.groups.length) ? 0 : cg
    },
    updateParticipantGroupDetails (groupIndex, participant) {
      if (!this.participantGroupDetails[groupIndex]) {
        this.participantGroupDetails[groupIndex] = {
          tLength: 0,
          fLength: 0,
          mLength: 0
        }
      }
      this.participantGroupDetails[groupIndex].tLength++
      participant.gender === 'M'
      ? this.participantGroupDetails[groupIndex].mLength++ 
      : this.participantGroupDetails[groupIndex].fLength++
    },
    sortByProp (subj, prop, sortDir) {
      if (sortDir) {
        this.sortDir = sortDir
      }
      subj.sort((a, b) => (a[prop] > b[prop]) ? this.sortDir !== 'asc' ? -1 : 1 : this.sortDir !== 'asc' ? 1 : -1)
      this.sortDir = this.sortDir !== 'asc' ? 'asc' : 'desc'
    },
    sortByAge (subj) {
      subj.sort((a, b) => (a.age > b.age) ? 1 : (a.age === b.age) ? ((a.fullName > b.fullName) ? 1 : -1) : -1 )
    },
    sortBySkillLevel (subj) {
      subj.sort((a, b) => (a.skillLevel > b.skillLevel) ? 1 : (a.skillLevel === b.skillLevel) ? (a.age > b.age) ? 1 : (a.age === b.age) ? ((a.fullName > b.fullName) ? 1 : -1) : -1 : -1 )
    },
    sortParticipantGroupsByProp (prop, sortDir) {
      for (const i in this.participantGroups) {
        if (prop === 'age') {
          this.sortByAge(this.participantGroups[i], prop, 'asc')
        } else {
          this.sortBySkillLevel(this.participantGroups[i], prop, 'asc')
        }
      }
    },
    moveObjectFromPool (evt, index) {
      const to = evt.target.value
      const participant = { ...this.participantOptions[index] }
      this.participantOptions.splice(index, 1)
      this.participantGroups[to].push(participant)
      if (evt.target) {
        evt.target.value = ''
      }
    },
    moveObject(from, evt, index) {
      if (evt.target.value === "-") {
        this.returnObjectToPool (from, index)
      } else if (evt.target.value !== "") {
        this.moveObjectFromGroup (from, evt, index)
      }
      // @todo reset all selects to null
      evt.target.value = ""
    },
    moveObjectFromGroup (from, evt, index) {
      const to = evt.target.value
      const participant = {...this.participantGroups[from][index] }
      this.participantGroups[from].splice(index, 1)
      this.participantGroups[to].push(participant)
      if (evt.target) {
        evt.target.value = ''
      }
    },
    returnObjectToPool (from, index) {
      const participant = { ...this.participantGroups[from][index] }
      this.participantGroups[from].splice(index, 1)
      this.participantOptions.push(participant)
    },
    iniParticipantGroups () {
      this.participantGroups = {}
      for (const i in this.groups) {
        this.participantGroups[this.groups[i].value] = []
      }
    },
    iniEventGroups () {
      if (this.eventGroups.length === 0) {
        return
      }
      this.autoAgeGroups = false

      for (let i = 0; i < this.eventGroups.length; i++)
      {
        let coach = this.eventGroups[i].coach[0] || null
        // set ageGroup
        let ageGroup = {
          value: i, // key
          name: this.eventGroups[i].name,
          ageMin: this.eventGroups[i].ageMin,
          ageMax: this.eventGroups[i].ageMax,
          gender: this.eventGroups[i].gender,
          coach: coach
        }
        let k = `${this.eventGroups[i].ageMin} - ${this.eventGroups[i].ageMax}`
        if (typeof this.ageGroups[k] === 'undefined') {
          this.$set(this.ageGroups, k, [])
        }
        this.ageGroups[k].push(ageGroup)

        // set group
        let group = {
          value: i,
          id: this.eventGroups[i].id,
          name: this.eventGroups[i].name,
          ageMin: this.eventGroups[i].ageMin,
          ageMax: this.eventGroups[i].ageMax,
          event: this.eventGroups[i]['@id'],
          coach: coach
        }
        this.groups.push(group)

        // set participantGroups
        this.$set(this.participantGroups, i, [])
        
        for (let p = 0; p < this.eventGroups[i].participants.length; p++) {
          let pi = this.participantOptions.findIndex(data => data.id === this.eventGroups[i].participants[p].id)
          if (pi !== -1) {
            this.updateParticipantGroupDetails(i, this.participantOptions[pi])
            this.participantGroups[i].push(this.participantOptions[pi])
            this.participantOptions.splice(pi, 1)
          }
        }
      }
      // this.ageGroups.sortByProp()
    },
    async saveEventGroups () {
      try {
        const save = []
        for (const i in this.groups) {
          save[i] = {}
          save[i].name = this.groups[i].name
          save[i].ageMin = this.groups[i].ageMin
          save[i].ageMax = this.groups[i].ageMax
          save[i].event = this.event['@id']
          save[i].coach = this.groups[i].coach ?
            [ this.groups[i].coach ] : []
          save[i].participants = this.participantGroups[i].map(participant => {
            return constants.IRI_PARTICIPANTS + participant.id
          })

          if (!this.groups[i].id) {
            // save entry
            await this.postEventGroup(save[i]).then(resp => {
              this.groups[i].id = resp.id
            }, e => {
              console.log('error', e)
            })
          } else {
            // update entry
            save[i].id = this.groups[i].id
            await this.putEventGroup(save[i]).then(resp => {}, e => {
              console.log('error', e)
            })
          }
          // console.log('saveEventGroups-' + i, save)
        }
      } catch (e) {
        console.log('error', e)
      }
      this.showSuccess()
    },
    async resetEventGroups () {
      const eventGroup = await this.getEventGroups({
        event: this.event.id
      })
      await Promise.all(eventGroup.map(async (g) => {
        await this.deleteEventGroup(g.id)
      }))
      window.location.reload()
      // this.$router.push({ name: 'events' })
    },
    log (evt) {
      console.log('log', evt)
    }
  },
  watch: {
    // ageGroupSettings: {
    //   handler: 'updateAgeGroupSettings',
    //   deep: true
    // }
  },
  created () {
    this.newGroup = { ...NEW_GROUP }
  },
  async mounted () {
    this.event = await this.getEvent({
      id: this.$route.params.eid
    })
    await this.getEventQuestions({
      event: this.$route.params.eid
    })
    // get coaches
    let coachParams = {
      roles: this.event.type.id !== 1 ? 'ROLE_COACH' : 'ROLE_COACH_JR',
      businesses: this.currentBusiness.id
    }
    if (!constants.LOOKUP_EXCLUDE.includes(this.event.sport.label)) {
      coachParams.sports = [this.event.sport['@id']]
    }
    await this.getUsers(coachParams)
    await this.getEventRegistrations({
      event: this.$route.params.eid,
      pagination: false
    }).then(resp => {
      // get skill level object
      const skillLvl = this.eventQuestions.find(q => q.id === 2)
      for (const i in resp) {
        const psl = resp[i].questionResponses[2] || null
        const participantSkillLvl = skillLvl.options.find(l => l['@id'].split('/')[3] == psl)
        this.participantOptions.push({
          id: resp[i].participant.id,
          fullName: resp[i].participant.fullName,
          age: this.dobToAge (resp[i].participant.dob),
          gender: resp[i].participant.gender.charAt(0).toUpperCase(),
          request: resp[i].request,
          skillLevel: psl ? participantSkillLvl.label.split(' ')[0] : null
        })
      }
      this.overAllAgeRange = this.participantsAgeRange(this.participantOptions)
    })
    this.iniParticipantGroups()
    this.iniAgeGroupSettings()
    // process saved groups
    await this.getEventGroups({
      event: this.$route.params.eid
    }).then(resp => {
      this.iniEventGroups()
    })
    
    // this.$intro().start()
    // this.$intro().showHints()
  },
  components: { /* Draggable, VueIntro */ }
}
</script>

<style>
.sl-beginner {
  background-color: red;
  color: white;
}
.sl-intermediate {
  background-color: yellow;
  color: black;
}
.sl-elite {
  background-color: green;
  color: white;
}
.sl-advanced {
  background-color: blue;
  color: white;
}
.roster-create-pg .card-body {
  padding: 0;
}
.group-age {
  width: 32px;
}
</style>